import { useContext } from 'react';

import { PermissionsContext } from 'app/shared/context/Permissions';

const usePermission = (permissionName: string, resourceId?: number) => {
  const permissionsContext = useContext(PermissionsContext);
  return permissionsContext.hasPermission(permissionName, resourceId);
};

export default usePermission;
